import PropTypes from "prop-types";
import React from "react";
// import Footer from "../components/footer";

import Header from "../components/header";

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen overflow-x-hidden font-sans text-gray-900">
      <Header />

      {/* <main className="flex-1 w-full mx-auto"> */}
      {children}
      {/* </main> */}

      {/* <Footer /> */}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
