import React, {useState} from "react"
import PropTypes from 'prop-types'
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const languageName = {
  en: "English",
  es: "Español",
}

const Language = () => {

  const [isOpen, set] = useState(false);

  const toggle = () => {
    set(!isOpen)
  }

  return (<>
    <div className="block mt-4 text-white no-underline md:inline-block md:mt-0 md:ml-6">
      <div className="relative hidden lg:block">
        <button className="relative z-10 flex cursor-pointer" onClick={toggle}>
          <IntlContextConsumer>    
            {({language: currentLocale}) => 
              languageName[currentLocale].slice(0, 2)
            }
          </IntlContextConsumer>
          <svg viewBox="0 0 16 16" className="self-center w-3 h-3 ml-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
          </svg>
        </button>
        <button 
          tabIndex="-1"
          hidden={!isOpen}
          className="fixed inset-0 w-full h-full bg-black bg-opacity-25 cursor-pointer"
          onClick={() => set(false)}/>
        <div hidden={!isOpen} className="absolute right-0 w-20 py-2 bg-white shadow-xl">
          <IntlContextConsumer>    
            {({ languages, language: currentLocale }) =>
              languages.map(language => (
                currentLocale != language? 
                  <a
                    className="block px-3 py-2 text-gray-800 hover:bg-indigo-500 hover:text-white"
                    key={language}
                    onClick={() => changeLocale(language)}
                  >
                    {languageName[language]}
                  </a>
                :
                ''
              ))
            }
          </IntlContextConsumer>
        </div>
      </div>
    </div>
    <div className={'lg:hidden block mt-4 text-white no-underline'}>
      <IntlContextConsumer>    
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
              <a
                className={language == currentLocale? "mr-4 cursor-pointer text-gray-500" : "mr-4 cursor-pointer"}
                key={language}
                onClick={() => changeLocale(language)}
              >
                {languageName[language]}
              </a>
          ))
        }
      </IntlContextConsumer>
    </div>
  </>)
}

Language.propTypes = {
  className: PropTypes.string,
};

export default Language
