import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import Languaje from "./language"
import { useIntl } from 'gatsby-plugin-intl'

import Scrollspy from 'react-scrollspy'
import scrollTo from 'gatsby-plugin-smoothscroll';


const Header = () => {
  const [isExpanded, toggleExpansion] = useState(false)
  const intl = useIntl()
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header className="fixed z-20 w-full max-w-full md:pl-10 bg-flyer-blue">
      <div className="flex flex-wrap items-center justify-between max-w-4xl p-4 mx-auto lg:p-6">
        <Link to="/">
          <h1 className="flex items-center font-serif text-white no-underline">
            <span className="text-lg font-bold tracking-tight">
              {site.siteMetadata.title}
            </span>
          </h1>
        </Link>

        <button
          className="items-center block px-3 py-2 text-white border border-white rounded lg:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`font-serif ${
            isExpanded ? `block` : `hidden`
          } lg:block lg:items-center w-full lg:w-auto`}
        >
          <Scrollspy items={ ['section-1', 'section-2', 'section-3', 'section-4', 'section-6', 'section-7'] } 
            className='block lg:inline-block'
            currentClassName="border px-2 py-1 border-1 border-white ">
            <button onClick={() => scrollTo('#section-1')} className="block mt-4 text-white no-underline lg:inline-block lg:mt-0 lg:ml-6" href="#section-1">{intl.formatMessage({ id: "navbar-start" })}</button>
            <button onClick={() => scrollTo('#section-2')} className="block mt-4 text-white no-underline lg:inline-block lg:mt-0 lg:ml-6" href="#section-2">{intl.formatMessage({ id: "navbar-project" })}</button>
            <button onClick={() => scrollTo('#section-3')} className="block mt-4 text-white no-underline lg:inline-block lg:mt-0 lg:ml-6" href="#section-3">{intl.formatMessage({ id: "navbar-team" })}</button>
            <button onClick={() => scrollTo('#section-4')} className="block mt-4 text-white no-underline lg:inline-block lg:mt-0 lg:ml-6" href="#section-4">{intl.formatMessage({ id: "navbar-lessons" })}</button>
            {/* <button onClick={() => scrollTo('#section-5')} className="block mt-4 text-white no-underline lg:inline-block lg:mt-0 lg:ml-6" href="#section-5">Upcoming</button> */}
            <button onClick={() => scrollTo('#section-6')} className="block mt-4 text-white no-underline lg:inline-block lg:mt-0 lg:ml-6" href="#section-6">{intl.formatMessage({ id: "navbar-testimonials" })}</button>
            <button onClick={() => scrollTo('#section-7')} className="block mt-4 text-white no-underline lg:inline-block lg:mt-0 lg:ml-6" href="#section-7">{intl.formatMessage({ id: "navbar-contact" })}</button>
          </Scrollspy>
          <Languaje />
        </nav>
      </div>
    </header>
  );
}

export default Header;
